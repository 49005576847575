// export default {
//   client_id: YOUR_CLIENT_ID,
//   project_id: YOUR_PROJECT_ID,
//   auth_uri: YOUR_AUTH_URI,
//   token_uri: YOUR_TOKEN_URI,
//   auth_provider_x509_cert_url: YOUR_TOKEN_URI,
//   client_secret: YOUR_CLIENT_SECRET,
//   redirect_uris: [
//     YOUR_REDIRECT_URIS,
//     YOUR_REDIRECT_URIS,
//     YOUR_REDIRECT_URIS
//   ],
//   javascript_origins: [
//     YOUR_JAVASCRIPT_ORIGINS,
//     YOUR_JAVASCRIPT_ORIGINS,
//     YOUR_JAVASCRIPT_ORIGINS
//   ]
// }
